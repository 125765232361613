<template>
<CRow>
  <CCol col="12" xl="12">
    <CCard>
      <CCardHeader>
        <CCol col="12" style="font-size: 25px;">  Show User Details   </CCol>
      </CCardHeader>
      <CCardBody>
        <div class="row">
            <div class="col-md-6">
              <h2 class="title">Basic information</h2>
              <div class="line"></div>
                <table class="table table-bordered" style="text-align:start ">
                  <tr>
                    <th>Id   </th>
                    <td>{{ employee.id }}</td>
                  </tr>
                  <tr>
                    <th> Email </th>
                    <td>{{ employee.email }}</td>
                  </tr>
                  <tr>
                    <th> CV   </th>
                    <td>
                      <a href="employee->cv_full_path" download>CV</a>
                    </td>
                  </tr>
                  <tr v-if="employee.educationLevel ">
                    <th> Education level   </th>
                    <td>{{ educationLevel.name  }}</td>
                  </tr>
                </table>

              <h2 class="title">Career details</h2>
               <div class="line"></div>
                <table class="table table-bordered" style="text-align:start ">
                  <tr v-if="employee.career_detail ">
                    <th> Salary  </th>
                    <td>{{ employee.career_detail.salary   }}</td>
                  </tr>
                  <tr v-if="employee.career_detail">
                    <th>  Experience </th>
                    <td v-if="employee.career_detail.experience == 1">No experience</td>
                    <td v-else-if="employee.career_detail.experience == 2">less than one  Year</td>
                    <td v-else-if="employee.career_detail.experience == 3">1 Year</td>
                    <td v-else-if="employee.career_detail.experience == 4">2 Year</td>
                    <td v-else-if="employee.career_detail.experience == 5">3 Year</td>
                    <td v-else-if="employee.career_detail.experience == 6">4 Year</td>
                    <td v-else-if="employee.career_detail.experience == 7">5 Year</td>
                    <td v-else-if="employee.career_detail.experience == 8">6 Year</td>
                    <td v-else-if="employee.career_detail.experience == 9">7 Year</td>
                    <td v-else-if="employee.career_detail.experience == 10">8 Year</td>
                    <td v-else-if="employee.career_detail.experience == 11">9 Year</td>
                    <td v-else-if="employee.career_detail.experience == 12">10 Year</td>
                    <td v-else-if="employee.career_detail.experience == 13">11 Year</td>
                    <td v-else-if="employee.career_detail.experience == 14">12 Year</td>
                    <td v-else-if="employee.career_detail.experience == 15">13 Year</td>
                    <td v-else-if="employee.career_detail.experience == 16">14 Year</td>
                    <td v-else-if="employee.career_detail.experience == 17">15 Year</td>
                    <td v-else-if="employee.career_detail.experience == 18">More than 15 Year</td>
                  </tr>
                  <tr v-if="jobType">
                    <th>  Job Type </th>
                    <td>{{ jobType}}</td>
                  </tr>
                  <tr v-if="employee.career_detail">
                    <th>  Candidates Status </th>
                    <td v-if="employee.career_detail.candidates_status == 1">I am unemployed and desperate looking for a job</td>
                    <td v-else-if="employee.career_detail.candidates_status == 2">I am actively looking for new jobs and opportunities</td>
                    <td v-else-if="employee.career_detail.candidates_status == 3">I am happy where I am but don’t mind finding good opportunitie</td>
                    <td v-else-if="employee.career_detail.candidates_status == 4">I am only interested in very specific opportunities</td>
                    <td v-else-if="employee.career_detail.candidates_status == 5">I am not looking for a job</td>

                  </tr>
                  <tr v-if="careerLevel">
                    <th>  Career level </th>
                    <td>{{ careerLevel }}</td>
                  </tr>
                   <tr v-if="employee">
                    <th>  Categories </th>
                    <td>
                      <span class="badge badge-info mx-1"  v-for="category in employee.categories" :key="category.id">
                      {{ category.name}}  </span>{{ employee.categories.name }}</td>
                  </tr>
                  <tr v-if="employee">
                    <th>  Job titles </th>
                    <td><span  class="badge badge-info mx-1" >{{employee.job_titles}}</span></td>
                  </tr>
                </table>
            </div>
            <div class="col-md-6">
              <h2 class="title">Personal Details</h2>
               <div class="line"></div>
                <table class="table table-bordered" style="text-align:start ">
                  <tr v-if="employee.personal_detail">
                    <th> Name   </th>
                    <td>{{ employee.personal_detail.first_name }} {{ employee.personal_detail.last_name }}</td>
                  </tr>
                  <tr v-if="employee.personal_detail">
                    <th>  Gender </th>
                    <td> {{ employee.personal_detail.gender }}</td>
                  </tr>
                   <tr v-if="employee.personal_detail">
                    <th> Date of birth  </th>
                    <td>{{ employee.personal_detail.data_of_birth  }}</td>
                  </tr>
                   <tr v-if="country">
                    <th>  Country </th>
                    <td> {{ country }}</td>
                  </tr>
                   <tr v-if="city">
                    <th> City </th>
                    <td> {{ city }}</td>
                  </tr>
                  <tr v-if="employee.contact_detail">
                    <th> Phone number   </th>
                    <td>{{ employee.contact_detail.phone }}</td>
                  </tr>
                   <tr v-if="employee.contact_detail">
                    <th> Another phone number   </th>
                    <td>{{ employee.contact_detail.another_phone }}</td>
                  </tr>
                </table>
                <h2 class="title">Skills </h2>
               <div class="line"></div>
                <table class="table table-bordered">
                  <tr>
                    <th>Skill name  </th>
                    <th>Proficiency</th>
                  </tr>
                   <tr v-for=" skill in employee.skills" :key="skill.id">
                    <td>{{ skill.skill_name }}</td>
                    <td>{{ skill.proficiency }}</td>
                  </tr>
                </table>
                <h2 class="title">Languages </h2>
               <div class="line"></div>
                <table class="table table-bordered">
                  <tr>
                    <th>Language name  </th>
                    <th>Proficiency</th>
                  </tr>
                   <tr v-for=" language in employee.languages" :key="language.id">
                    <td>{{ language.language_name }}</td>
                    <td>{{ language.proficiency }}</td>
                  </tr>
                </table>
            </div>
        </div>
          <br>
          <div class="row">
               <div class="col-md-8 m-auto">
               <h2 class="title">Education details </h2>
               <div class="line"></div>
                <table class="table table-bordered">
                  <tr>
                    <th>Education level</th>
                    <th>University  </th>
                    <th>Field of study </th>
                    <th>Degree</th>
                    <th>Start year</th>
                    <th>End year</th>
                  </tr>
                   <tr v-for="educationDetail in employee.education_detail" :key="educationDetail.id">
                    <td v-if="educationDetail.education_level_id = '1' "> Bachelor's degree</td>
                    <td v-else-if="educationDetail.education_level_id = '2' "> Master degree</td>
                    <td v-else-if="educationDetail.education_level_id = '3' "> Doctorate degree</td>
                    <td v-else></td>
                    <td>{{ educationDetail.university }}</td>
                    <td>{{ educationDetail.field_of_study }}</td>
                    <td>{{ educationDetail.degree }}</td>
                    <td>{{ educationDetail.start_year }}</td>
                    <td>{{ educationDetail.end_year }}</td>
                  </tr>
                </table>
              </div>

          </div>
          <div class="row">
               <div class="col-md-8 m-auto">
               <h2 class="title">Experience details </h2>
               <div class="line"></div>
                <table class="table table-bordered">
                  <tr>
                    <th>Job title  </th>
                    <th>Compony name </th>
                    <th>Start duration</th>
                    <th>End  duration</th>
                  </tr>
                   <tr v-for=" experienceDetail in employee.experience_detail" :key="experienceDetail.id">
                    <td>{{ experienceDetail.job_title }}</td>
                    <td>{{ experienceDetail.company_name }}</td>
                    <td>{{ experienceDetail.start_month }}, {{ experienceDetail.start_year }} </td>
                    <td>{{ experienceDetail.end_month }}, {{ experienceDetail.end_year }} </td>
                  </tr>
                </table>
              </div>

          </div>
          <br>
        <CButton color="warning" @click="goBack"> {{ $t('message.back')}}</CButton>
      </CCardBody>
    </CCard>
  </CCol>
</CRow>
</template>

<script>
export default {
  name: 'ShowEmployee',
  data () {
    return {
      employee: [],
      educationLevel: '',
      country: '',
      city: '',
      careerLevel: '',
      jobType: ''
    }
  },
  created () {
    console.log(this.$route.params.id)
    this.$http
      .get(`${this.$hostUrl}admin-dashboard/employees/${this.$route.params.id}`)
      .then((response) => {
        this.employee = response.data.data.employee
        this.educationLevel = response.data.data.educationLevel
        this.country = (response.data.data.country ? response.data.data.country.name : null)
        this.city = response.data.data.city
        this.careerLevel = (response.data.data.careerLevel ? response.data.data.careerLevel.name : null)
        this.jobType = (response.data.data.jobType ? response.data.data.jobType.name : null)
      })
  },
  methods: {
    goBack () {
      this.$router.push({ path: '/users' })
    }
  }
}
</script>
<style scoped>
  th {
    background-color: rgb(0, 85, 217);
    color: #f5f3f3;
  }

</style>
